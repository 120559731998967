import { Box, Chip as MuiChip, colors, Grid, styled } from '@mui/material';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';

type Props = {
  no: number;
  children?: React.ReactNode;
  grey?: boolean;
  forceLabel?: React.ReactNode;
};

const TaskItem: React.FC<Props> = ({
  no,
  children,
  grey = false,
  forceLabel,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Grid container alignItems="center">
        <Grid item>
          <Chip
            sx={{ mr: 4 }}
            grey={grey}
            no={no - 1}
            label={
              <span
                data-testid={
                  !isNullOrUndefined(forceLabel)
                    ? `manualDriving-${no}-route`
                    : `autoDriving-${no}-route`
                }
              >
                {!isNullOrUndefined(forceLabel)
                  ? forceLabel
                  : `${t('common.fms.route')}${no}`}
              </span>
            }
            variant="outlined"
          />
        </Grid>
        <Grid data-testid={`taskItem-${no}`} item>
          {children}
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default React.memo(TaskItem);

const Wrapper = styled(Box)`
  position: relative;

  &:after {
    height: 20px;
    border-left: solid 4px ${colors.grey[300]};
    margin-left: 36px;
    display: block;
    content: '';
  }

  &:last-of-type {
    &:after {
      display: none;
    }
  }
`;

type ChipProps = {
  no: number;
  grey: boolean;
};

const Chip = styled(MuiChip, {
  shouldForwardProp: (propName) => propName !== 'no' && propName !== 'grey',
})<ChipProps>`
  border-width: 2px;

  ${({ no, grey }) => {
    if (grey) {
      return css`
        border-color: ${colors.grey[300]};
      `;
    }
    const i = no % 4;
    if (i === 0) {
      return css`
        border-color: ${colors.amber[400]};
      `;
    }
    if (i === 1) {
      return css`
        border-color: ${colors.green[400]};
      `;
    }
    if (i === 2) {
      return css`
        border-color: ${colors.lightBlue[400]};
      `;
    }
    if (i === 3) {
      return css`
        border-color: ${colors.deepPurple[400]};
      `;
    }
  }}
`;
